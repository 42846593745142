// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <a-row class="usage-dashboard" :gutter="12">
    <a-col :xl="16" style="padding-left: 0; padding-right: 0;">
      <a-row>
        <a-card style="width: 100%">
          <a-row :gutter="24">
            <a-col
              class="usage-dashboard-chart-tile"
              :xs="12"
              :md="8"
              v-for="stat in stats"
              :key="stat.type">
              <a-card
                class="usage-dashboard-chart-card"
                :bordered="true"
                :loading="loading">
                <router-link v-if="stat.path" :to="{ path: stat.path, query: stat.query }">
                  <div
                    class="usage-dashboard-chart-card-inner">
                    <h3>{{ stat.name }}</h3>
                    <h2>
                      <render-icon :icon="stat.icon" :style="{color: stat.bgcolor}" />
                      {{ stat.count == undefined ? 0 : stat.count }}
                    </h2>
                  </div>
                </router-link>
              </a-card>
            </a-col>
          </a-row>
        </a-card>
      </a-row>
    </a-col>
    <a-col :xl="8">
      <chart-card :loading="loading" >
        <div class="usage-dashboard-chart-card-inner">
          <a-button>
            <router-link :to="{ name: 'event' }">
              {{ $t('label.view') + ' ' + $t('label.events') }}
            </router-link>
          </a-button>
        </div>
        <template #footer>
          <div class="usage-dashboard-chart-footer">
            <a-timeline>
              <a-timeline-item
                v-for="event in events"
                :key="event.id"
                :color="getEventColour(event)">
                <span :style="{ color: '#999' }"><small>{{ $toLocaleDate(event.created) }}</small></span><br/>
                <span :style="{ color: '#666' }"><small><router-link :to="{ path: '/event/' + event.id }">{{ event.type }}</router-link></small></span><br/>
                <resource-label :resourceType="event.resourcetype" :resourceId="event.resourceid" :resourceName="event.resourcename" />
                <span :style="{ color: '#aaa' }">({{ event.username }}) {{ event.description }}</span>
              </a-timeline-item>
            </a-timeline>
          </div>
        </template>
      </chart-card>
    </a-col>
  </a-row>
</template>

<script>
import { api } from '@/api'
import store from '@/store'

import ChartCard from '@/components/widgets/ChartCard'
import UsageDashboardChart from '@/views/dashboard/UsageDashboardChart'
import ResourceLabel from '@/components/widgets/ResourceLabel'
import wpapi from '../../wpApi/myApi'

export default {
  name: 'UsageDashboard',
  components: {
    ChartCard,
    UsageDashboardChart,
    ResourceLabel
  },
  props: {
    resource: {
      type: Object,
      default () {
        return {}
      }
    },
    showProject: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      showAction: false,
      showAddAccount: false,
      events: [],
      stats: [],
      project: {}
    }
  },
  created () {
    this.project = store.getters.project
    this.fetchData()
    this.$store.watch(
      (state, getters) => getters.project,
      (newValue, oldValue) => {
        if (newValue && newValue.id && (!oldValue || newValue.id !== oldValue.id)) {
          this.fetchData()
        } else if (store.getters.userInfo.roletype !== 'Admin') {
          this.fetchData()
        }
      }
    )
  },
  watch: {
    '$route' (to) {
      if (to.name === 'dashboard') {
        this.fetchData()
      }
    },
    resource: {
      deep: true,
      handler (newData, oldData) {
        this.project = newData
      }
    },
    '$i18n.global.locale' (to, from) {
      if (to !== from) {
        this.fetchData()
      }
    }
  },
  methods: {
    async fetchData () {
      this.listEvents()
      this.metricsLoaded = false
      this.stats = [{}, {}, {}, {}, {}, {}, {}]
      await wpapi.getAllTagDataFromAccountVms(this.accountIdInput).then(response => {
        this.groupedTagInfo = []
        this.allVmTagInfo = response.data
      }).catch(error => {
        this.$notifyError(error)
      })
      // list running VMs
      var vmParamsRunning = { state: 'Running', account: this.accountNameInput, domainid: this.domainIdInput }
      // vmParamsRunning = { state: 'Running', listall: true }
      await api('listVirtualMachines', vmParamsRunning).then(json => {
        var count = 0
        var vms = json.listvirtualmachinesresponse.virtualmachine
        var runningCPUs = 0
        var runningRAM = 0
        // get CPU and RAM amount out of each running vm
        for (var vmIndex in vms) {
          // check if any of the running VMs has a "noBill" Tag - dont take their CPUs and RAM into account if yes.
          if (this.allVmTagInfo.filter(x => x.uuId === vms[vmIndex].id).length > 0) {
            // license connected but ots not "NoBill"
            if (this.allVmTagInfo.filter(x => x.uuId === vms[vmIndex].id).filter(x => x.tagText === 'NoBill').length === 0) {
              runningCPUs = runningCPUs + vms[vmIndex].cpunumber
              runningRAM = runningRAM + vms[vmIndex].memory
            }
          } else {
            // no connected licenses
            runningCPUs = runningCPUs + vms[vmIndex].cpunumber
            runningRAM = runningRAM + vms[vmIndex].memory
          }
        }

        if (json && json.listvirtualmachinesresponse) {
          count = json.listvirtualmachinesresponse.count
        }
        this.runningVMCPU = runningCPUs
        this.runningVMRam = runningRAM
        // var tileColor = this.$config.theme['@dashboard-tile-runningvms-bg'] || '#dfe9cc'
        this.stats.splice(0, 1, { name: this.$t('label.running'), count: count, icon: 'desktop-outlined', bgcolor: 'green', path: '/vm', query: { state: 'running', filter: 'running' } })
      })
      // list stopped VMs
      var vmParamsStopped = { state: 'Stopped', account: this.accountNameInput, domainid: this.domainIdInput }
      await api('listVirtualMachines', vmParamsStopped).then(json => {
        var count = 0
        if (json && json.listvirtualmachinesresponse) {
          count = json.listvirtualmachinesresponse.count
        }
        this.stats.splice(1, 1, { name: this.$t('label.stopped'), count: count, icon: 'poweroff-outlined', bgcolor: 'red', path: '/vm', query: { state: 'stopped', filter: 'stopped' } })
        // var tileColor = this.$config.theme['@dashboard-tile-stoppedvms-bg'] || '#edcbce'
      })
      // list all VMs
      var vmParams = { account: this.accountNameInput, domainid: this.domainIdInput }
      await api('listVirtualMachines', vmParams).then(json => {
        var count = 0
        this.noBillVmList = []
        if (json && json.listvirtualmachinesresponse) {
          count = json.listvirtualmachinesresponse.count
        }
        var serverCPU = 0
        var clientCPU = 0
        var serverVMs = []
        var clientVMs = []
        var vms = json.listvirtualmachinesresponse.virtualmachine
        this.allVms = vms
        if (json.listvirtualmachinesresponse.virtualmachine && vms.length > 0) {
          for (var vmIndex in vms) {
            // get all VMs that have a "NoBill" Tag and save them in noBillVmList
            if (this.allVmTagInfo.filter(x => x.uuId === vms[vmIndex].id).length > 0) {
              if (this.allVmTagInfo.filter(x => x.uuId === vms[vmIndex].id).filter(x => x.tagText === 'NoBill').length > 0) {
                this.noBillVmList.push(vms[vmIndex])
              }
            }
            if (vms[vmIndex].state !== 'Destroyed' && this.allVmTagInfo.filter(x => x.uuId === vms[vmIndex].id).length >= 1 && this.allVmTagInfo.filter(x => x.uuId === vms[vmIndex].id).filter(x => x.tagType === 'NoBill').length === 0) {
              // vmTagInfo = VM where vm name === name of VM with the licenseTagInfo and tagType === OS
              var vmTagInfo = this.allVmTagInfo.filter(x => x.uuId === vms[vmIndex].id).filter(x => x.tagType === 'OS')[0]
              if (vmTagInfo) {
                var discountCalculated = this.discountList.filter(x => x.usageName === vmTagInfo.tagText)[0].discountCalculated !== undefined ? this.discountList.filter(x => x.usageName === vmTagInfo.tagText)[0].discountCalculated : 0
                if (vmTagInfo.tagText.toLowerCase().indexOf('server') !== -1) {
                  serverCPU = serverCPU + vms[vmIndex].cpunumber
                  serverVMs.push([vms[vmIndex].name, vms[vmIndex].cpunumber, discountCalculated])
                } else if (vmTagInfo.tagText.toLowerCase().indexOf('server') === -1) {
                  clientCPU = clientCPU + vms[vmIndex].cpunumber
                  clientVMs.push([vms[vmIndex].name, vms[vmIndex].cpunumber, discountCalculated])
                }
              }
            }
          }
          this.windowsServer = serverCPU
          this.windowsClients = clientCPU
          this.serverVMInfo = serverVMs
          this.clientVMInfo = clientVMs
        }
        this.stats.splice(2, 1, { name: this.$t('label.total.vms'), count: count, icon: 'number-outlined', path: '/vm' })
        // var tileColor = this.$config.theme['@dashboard-tile-totalvms-bg'] || '#ffffff'
      })
      // list Volumes
      var volumeParams = { account: this.accountNameInput, domainid: this.domainIdInput }
      await api('listVolumes', volumeParams).then(json => {
        var count = 0
        if (json && json.listvolumesresponse) {
          count = json.listvolumesresponse.count
        }
        var hdd = 0
        var ssd = 0
        var vms = json.listvolumesresponse.volume
        for (var vmIndex in vms) {
          var vmidInAllVmTagInfo = this.allVmTagInfo.filter(x => x.uuId === vms[vmIndex].virtualmachineid)
          // check if any of the volumes has VM with "noBill" Tag - dont take their DISK Size into account if yes.
          if (vmidInAllVmTagInfo.length > 0) {
            // license connected but its not "NoBill"
            if (vmidInAllVmTagInfo.filter(x => x.tagText === 'NoBill').length === 0) {
              if (vms[vmIndex].type === 'DATADISK' && vms[vmIndex].diskofferingname && vms[vmIndex].diskofferingname.includes('HDD')) {
                hdd = hdd + (vms[vmIndex].size / 1024 / 1024 / 1024)
              } else {
                ssd = ssd + (vms[vmIndex].size / 1024 / 1024 / 1024)
              }
            } else {
              // vm, connected to this volume, that has licenses in general AND a noBill Tag
              var vmInNoBillVMList = this.noBillVmList.filter(x => x.id === vms[vmIndex].virtualmachineid)
              if (vmInNoBillVMList.length > 0) {
                if (vms[vmIndex].type === 'DATADISK' && vms[vmIndex].diskofferingname && vms[vmIndex].diskofferingname.includes('HDD')) {
                  if (vmInNoBillVMList[0].hdd === undefined) {
                    vmInNoBillVMList[0].hdd = (vms[vmIndex].size / 1024 / 1024 / 1024)
                  } else {
                    vmInNoBillVMList[0].hdd = vmInNoBillVMList[0].hdd + (vms[vmIndex].size / 1024 / 1024 / 1024)
                  }
                } else {
                  if (vmInNoBillVMList[0].ssd === undefined) {
                    vmInNoBillVMList[0].ssd = (vms[vmIndex].size / 1024 / 1024 / 1024)
                  } else {
                    vmInNoBillVMList[0].ssd = vmInNoBillVMList[0].ssd + (vms[vmIndex].size / 1024 / 1024 / 1024)
                  }
                }
              }
            }
          } else {
            // no connected licenses
            if (vms[vmIndex].type === 'DATADISK' && vms[vmIndex].diskofferingname && vms[vmIndex].diskofferingname.includes('HDD')) {
              hdd = hdd + (vms[vmIndex].size / 1024 / 1024 / 1024)
            } else {
              ssd = ssd + (vms[vmIndex].size / 1024 / 1024 / 1024)
            }
          }
        }
        this.hddData = hdd
        this.ssdData = ssd
        this.stats.splice(3, 1, { name: this.$t('label.total.volume'), count: count, icon: 'database-outlined', path: '/volume' })
      }).catch(error => {
        this.$notifyError(error)
        this.changeAccountDisplayLoading = false
      })
      // list Networks
      var networkParams = { account: this.accountNameInput, domainid: this.domainIdInput }
      await api('listNetworks', networkParams).then(json => {
        var count = 0
        if (json && json.listnetworksresponse) {
          count = json.listnetworksresponse.count
        }
        this.stats.splice(4, 1, { name: this.$t('label.total.network'), count: count, icon: 'apartment-outlined', path: '/guestnetwork' })
        // var tileColor = this.$config.theme['@dashboard-tile-totalnetworks-bg'] || '#ffffff'
      })
      // list publicIpAdresses
      await api('listPublicIpAddresses', { account: this.accountNameInput, domainid: this.domainIdInput, allocatedonly: true }).then(json => {
        var count = 0
        if (json && json.listpublicipaddressesresponse) {
          if (json.listpublicipaddressesresponse.count !== undefined) {
            count = json.listpublicipaddressesresponse.count
          } else { count = 0 }
          if (json.listpublicipaddressesresponse.publicipaddress !== undefined) {
            this.allocatedIps = json.listpublicipaddressesresponse.publicipaddress.length
          } else { this.allocatedIps = 0 }
        }
        this.stats.splice(5, 1, { name: this.$t('label.public.ip.addresses'), count: count, icon: 'environment-outlined', path: '/publicip' })
        // var tileColor = this.$config.theme['@dashboard-tile-totalips-bg'] || '#ffffff'
      })
      // list VPCs
      var vpcParams = { account: this.accountNameInput, domainid: this.domainIdInput }
      await api('listVPCs', vpcParams).then(json => {
        var count = 0
        if (json && json.listvpcsresponse) {
          this.vpcs = json.listvpcsresponse.vpc
          count = json.listvpcsresponse.count
        }
        this.stats.splice(6, 1, { name: 'VPC´s', count: count, icon: 'deployment-unit-outlined', path: '/vpc' })
      })
      this.metricsLoaded = true
    },
    listEvents () {
      const params = {
        page: 1,
        pagesize: 6,
        listall: true
      }
      this.loading = true
      api('listEvents', params).then(json => {
        this.events = []
        this.loading = false
        if (json && json.listeventsresponse && json.listeventsresponse.event) {
          this.events = json.listeventsresponse.event
        }
      })
    },
    getEventColour (event) {
      if (event.level === 'ERROR') {
        return 'red'
      }
      if (event.state === 'Completed') {
        return 'green'
      }
      return 'blue'
    },
    onTabChange (key) {
      this.showAddAccount = false

      if (key !== 'Dashboard') {
        this.showAddAccount = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
  :deep(.usage-dashboard) {

    &-chart-tile {
      margin-bottom: 12px;
    }

    &-chart-card {
      padding-top: 24px;
    }

    &-chart-card-inner {
      text-align: center;
    }

    &-footer {
       padding-top: 12px;
       padding-left: 3px;
       white-space: normal;
    }
  }

  @media (max-width: 1200px) {
    .ant-col-xl-8 {
      width: 100%;
    }
  }
</style>
